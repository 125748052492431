import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import OneColVert from "src/components/oneColVert"
import {isIeEdge,isChrome,isFirefox,isSafari} from "@tightrope/lpscripts/browserdetect"
import FFDisclosure from "src/components/ffdisclosure"

const simData = import("./data/data.json");

export default function prdbest() {

  let css;
       if(isFirefox()){
         css = `
         #onecolvert-module--onecolvert .footerlinkssingle-module--br {
          background: transparent;
          padding: 10px;
          font-size:12px;
          color:#666;
        }
        #onecolvert-module--onecolvert .footerlinkssingle-module--br a {
          color:#666;
        }
         `;
       }

  return(
    <HomepageLayout>
    <Helmet>
    <style type="text/css">
     {css}
         </style>
    <title>Share Links and Copied Text to Social Media - Easy Share</title></Helmet>
    <section>
      <OneColVert data={simData}>
      <FFDisclosure></FFDisclosure>
    </OneColVert>
      </section>
    </HomepageLayout>
  )
}
